import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { OAuthService } from "angular-oauth2-oidc";
import { of } from "rxjs";
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { jwtDecode } from "jwt-decode";

import { environment } from "../../environments/environment";
import { AppStore } from "../store";
import * as UserActions from './user.actions';
import { UserService } from './user.service';
import { EmitAlertAction } from '../shared';


@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppStore>,
        private service: UserService,
        private oauthService: OAuthService,
        private router: Router
    ) {}

    userLogout$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.UserLogoutAction),
        map(action => {
            let oauthLogout = () => {
                this.oauthService.logOut({
                    client_id: environment.authConfig.clientId,
                    logout_uri: window.location.origin + "/"
                });
            };
    
            sessionStorage.removeItem("client_id");
    
            this.service.revokeRefreshToken().subscribe({
                next: () => {
                    console.log("Refresh token revoked successfully.");
                    oauthLogout();
                },
                error: (err) => {
                    console.log("Failed to revoke refresh token:", err);
                    oauthLogout();
                }
            });
        })
    ), { dispatch: false });

    decodeUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.DecodeUserInfoAction),
        map((action) => {
            const decoded = jwtDecode(action.payload);
            sessionStorage.setItem("client_id", decoded.sub);
        })
    ), { dispatch: false });

    fetchUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.FetchUserInfoAction),
        exhaustMap(action => {
            return this.service.getUserInfo().pipe(
                map(response => {
                    return UserActions.FetchUserInfoSuccessAction(response);
                }),
                catchError(err => of(UserActions.FetchUserInfoFailureAction(err)))
            );
        })
    ));

    fetchUserInfoSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.FetchUserInfoSuccessAction),
        map((action) => {
            this.router.navigateByUrl("dashboard");
        })
    ), { dispatch: false });

    fetchUserInfoFailure$ = createEffect(() => this.actions$.pipe(
        ofType(UserActions.FetchUserInfoFailureAction),
        map((action) => {
            const err = action.payload;
            
            if (err.status >= 500) {
                this.store.dispatch(EmitAlertAction({
                    message: "We encountered an error. Please check back again later.",
                    alert_type: "error"
                }));

            } else if (err.status === 403) {
                this.store.dispatch(EmitAlertAction({
                    message: "Login session expired. Please login again.",
                    alert_type: "info"
                }));

                this.store.dispatch(UserActions.UserLogoutAction());

            } else if (err.status == 404) {
                const idToken = this.oauthService.getIdToken();
                if (idToken) {
                    this.store.dispatch(UserActions.DecodeUserInfoAction(idToken));
                }

                this.router.navigateByUrl("signup");

            } else if (err.status === 300) {
                const idToken = this.oauthService.getIdToken();
                if (idToken) {
                    this.store.dispatch(UserActions.DecodeUserInfoAction(idToken));
                }

                this.store.dispatch(UserActions.UserUpdateAction(err.error));
                this.router.navigateByUrl(err.error.Location);
            }
        })
    ), { dispatch: false });
}