import { Pipe, PipeTransform } from "@angular/core";
import Web3 from "web3";


@Pipe({ name: "fromWei" })
export class FromWeiPipe implements PipeTransform {
    private web3: any;

    constructor () {
        this.web3 = new Web3(window.ethereum);
    }

    transform(wei: number): string {
        // return parseFloat(this.web3.utils.fromWei(wei, "ether"));
        return this.web3.utils.fromWei(wei, "ether");
    }
}