<div class="body-wrapper">
    <div class="header">
        <app-header></app-header>
    </div>

    <div class="body-con">
        <router-outlet></router-outlet>
    </div>
</div>

<app-alerts></app-alerts>