<div [ngSwitch]="errorCode" class="errors-con">
    <div *ngSwitchDefault>
        <div class="img-con">
            <img src="assets/images/errors/error.png">
        </div>

        <div class="text-con">
            <h3 class="text-title">
                Oops!
            </h3>

            <div class="description">
                Looks like we can't find what you're looking for
            </div>
        </div>
    </div>
</div>