import { Pipe, PipeTransform } from "@angular/core";
import { formatCurrency } from "@angular/common";


@Pipe({ name: "roundCost" })
export class CostRoundingPipe implements PipeTransform {
    constructor () {}

    transform(cost: number): string {
        let converted = 0;

        if (cost <= 0) {
            converted = 0;
            
        } else if (cost <= 0.01) {
            converted = 0.01;

        } else {
            converted = Math.round(cost * 100) / 100;
        }

        return formatCurrency(converted, "en", "$") + " USD";
    }
}