import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DialogModule } from "primeng/dialog";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ToastModule } from "primeng/toast";
import { ProgressBarModule } from "primeng/progressbar";
import { TableModule } from "primeng/table";
import { ChartModule } from "primeng/chart";
import { CalendarModule } from "primeng/calendar";
import { TooltipModule } from "primeng/tooltip";
import { ToggleButtonModule } from "primeng/togglebutton";
import { InputTextModule } from "primeng/inputtext";
import { CheckboxModule } from "primeng/checkbox";
import { MultiSelectModule } from "primeng/multiselect";

const PRIMENG_MODULES: any[] = [
    DialogModule,
    ButtonModule,
    DropdownModule,
    OverlayPanelModule,
    ToastModule,
    ProgressBarModule,
    TableModule,
    ChartModule,
    CalendarModule,
    TooltipModule,
    ToggleButtonModule,
    InputTextModule,
    CheckboxModule,
    MultiSelectModule
];

import { ThemeSwitcherComponent } from "./components/theme-switcher";
import { LoginComponent } from "./components/login";


import {
    FromWeiPipe,
    UsdCostPipe,
    ApproximatePercentagePipe,
    ChainIdPipe,
    CostRoundingPipe,
    CoinCostPipe,
    FullNumberPipe
} from "./pipes";

const PIPES: any[] = [
    FromWeiPipe,
    UsdCostPipe,
    ApproximatePercentagePipe,
    ChainIdPipe,
    CostRoundingPipe,
    CoinCostPipe,
    FullNumberPipe
];


@NgModule({
    declarations: [
        ThemeSwitcherComponent,
        LoginComponent,
        ...PIPES
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...PRIMENG_MODULES
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThemeSwitcherComponent,
        LoginComponent,
        ...PRIMENG_MODULES,
        ...PIPES
    ],
    providers: [
        ...PIPES
    ]
})
export class SharedModule {}