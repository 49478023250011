import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Renderer2 } from "@angular/core";
import { formatDate } from "@angular/common";
import { Store, ActionsSubject } from "@ngrx/store";
import { Subscription, firstValueFrom, filter } from "rxjs";
import { Table } from "primeng/table";

import { AppStore } from "../store";
import { EmitAlertAction } from "../shared";
import { ChainIdPipe, FullNumberPipe, CoinCostPipe, CostRoundingPipe } from "../shared/pipes";
import { getTheme } from "../layout";
import { DashboardService } from "./dashboard.service";
import { UserActionTypes } from "../user/user.actions";


@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(Table) table: Table;

    private subscriptions: Subscription[] = [];
    private chartRaw: any[] = [];

    public theme: string;
    public graphEmptyState: boolean = true;
    public maxDate: Date = new Date();
    public visibility: any = {};
    public variableStorage: any = {};
    public chartOptions: any = {};
    public chartData: any;
    public statistics: any = {};
    public maximums: any = {};
    public selectedMonth: Date;
    public showExportDropdown: boolean = false;

    public gasCoinOptions: string[] = [];
    public showCoinStats: string[] = [];
    
    public history: any[] = [];
    public tableLoading: boolean;
    public totalRecords: number;
    public selectAllHistory: boolean = false;
    public tableColumns: any[] = [];
    public selectedRows: any[] = [];
    public exportColumns: any[] = [];
    public showUTCDate: boolean = true;

    public tableRows: number = 10;
    public tableDefaultSort: number = -1;
    public tableSortOrder: number = -1;
    public tableSortField: string = "date";

    constructor(
        private store: Store<AppStore>,
        public renderer: Renderer2,
        private actions: ActionsSubject,
        private service: DashboardService,
        private chainIdPipe: ChainIdPipe,
        private fullNumberPipe: FullNumberPipe,
        private coinCostPipe: CoinCostPipe,
        private costRoundingPipe: CostRoundingPipe
    ) {}

    ngOnInit(): void {
        // Setup defaults
        this.visibility = {
            key: false,
            secret: false
        };
        
        this.variableStorage = {
            key: "",
            secret: ""
        };

        this.statistics = {
            coin_breakdown: {},
            gas_coin: 0,
            gas_usd: 0,
            mints: 0,
            requests: 0
        };

        this.maximums = {
            mints: 0,
            requests: 0
        };

        this.tableColumns = [
            {
                field: "tokenId",
                header: "Token ID"
            },
            {
                field: "chain",
                header: "Chain"
            },
            {
                field: "coinCost",
                header: "GAS Fee"
            },
            {
                field: "usdCost",
                header: "USD Equivalent"
            },
            {
                field: "dateUTC",
                header: "Used On"
            }
        ];
        this.exportColumns = this.tableColumns.map((col) => ({ title: col.header, dataKey: col.field }));
        this.tableLoading = true;

        this.history = [];
        this.selectedMonth = new Date();

        this.initChart();
        this.updateData();

        this.subscriptions.push(
            this.actions.pipe(filter(action =>
                action.type === UserActionTypes.USER_UPDATE
            )).subscribe((action: any) => {
                this.fetchUserData();
            })
        );

        // Run once on page load
        let client_id = sessionStorage.getItem("client_id");
        if (client_id) {
            this.fetchUserData();
        }

        firstValueFrom(this.service.getLimits()).then(limits => {
            this.maximums = limits;

        }).catch(err => {
            console.log("Error fetching user maximums:", err);
        });

        this.subscriptions.push(
            this.store.select(getTheme).subscribe(theme => {
                this.theme = theme;
            })
        );
    }

    ngAfterViewInit() {
        this.renderer.listen("body", "click", event => {
            if (!event.defaultPrevented) {
                this.showExportDropdown = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    initChart() {
        const textColor = "#fff";
        const textColorSecondary = "#fff";
        const surfaceBorder = "#000";

        this.chartOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            interaction: {
                intersect: false,
                mode: 'index'
            },
            plugins: {
                legend: false,
                tooltip: {
                    callbacks: {
                        labelColor: (context: any) => {
                            return {
                                borderColor: context.dataset.backgroundColor,
                                backgroundColor: context.dataset.borderColor
                            };
                        },
                        label: (context: any) => {
                            let label = " ";
                            label += (context.formattedValue + " " + context.dataset.label);

                            return label;
                        },
                        afterLabel: (context: any) => {
                            if (context.datasetIndex === 1) {
                                return " " + this.costRoundingPipe.transform(this.chartRaw[context.dataIndex].usd);

                            } else {
                                return "";
                            }
                        }
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        callback: (value: any, index: any, ticks: any) => {
                            if (index === 0 || index === (ticks.length - 1)) {
                                return this.chartData.labels[index];
                            }

                            return "";
                        }
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: surfaceBorder
                    }
                },
                y1: {
                    type: "linear",
                    display: false,
                    position: "right",
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: surfaceBorder
                    }
                }
            }
        };
    }

    populateChart() {
        this.chartData = {
            labels: this.chartRaw.map(raw => raw.dateString),
            datasets: [
                {
                    label: "mints",
                    data: this.chartRaw.map(raw => raw.mints),
                    yAxisID: "y",
                    fill: true,
                    tension: 0.4,
                    borderColor: "#5E7CFC",
                    backgroundColor: "#5E7CFC40",
                    pointRadius: 0
                },
                {
                    label: "ETH",
                    data: this.chartRaw.map(raw => raw.ether),
                    yAxisID: "y1",
                    fill: true,
                    tension: 0.4,
                    borderColor: "#5E7CFC",
                    backgroundColor: "#5E7CFC40",
                    pointRadius: 0
                },
                {
                    label: "$",
                    data: this.chartRaw.map(raw => raw.usd),
                    hidden: true,
                    yAxisID: "y1",
                    fill: true,
                    tension: 0.4,
                    borderColor: "#9EB0FD",
                    backgroundColor: "#9EB0FD40",
                    pointRadius: 0
                },
                {
                    label: "requests",
                    data: this.chartRaw.map(raw => raw.requests),
                    yAxisID: "y",
                    fill: true,
                    tension: 0.4,
                    borderColor: "#2E3E7A",
                    backgroundColor: "#2E3E7A40",
                    pointRadius: 0
                }
            ]
        };
    }

    convertHistoricalData(data: any[]) {
        return data.map((row: any) => {
            return {
                tokenId: row.token_id,
                chain: this.chainIdPipe.transform(row.chain),
                chainId: row.chain,
                contract_address: row.contract_address,
                coinCostShort: this.coinCostPipe.transform(row.coin_cost) + " " + this.chainIdPipe.transform(row.chain, "coin_short"),
                coinCost: this.fullNumberPipe.transform(row.coin_cost) + " " + this.chainIdPipe.transform(row.chain, "coin_short"),
                usdCost: this.costRoundingPipe.transform(row.usd_cost),
                usdCostRaw: row.usd_cost,
                date: row.date,
                dateUTC: formatDate(row.date, "MM-dd-yy h:mm a", "en-US", "UTC") + " UTC"
            };
        });
    }

    loadHistory(event: any) {
        this.tableLoading = true;

        setTimeout(() => {
            firstValueFrom(
                this.service.getHistoricalData(
                    this.selectedMonth,
                    event
                )
            ).then((res: any) => {
                this.history = this.convertHistoricalData(res.history);
                this.totalRecords = res.totalRecords;
                this.tableLoading = false;
            });
        }, 500);
    }

    onSelectionChange(value: any[] = []) {
        this.selectAllHistory = value.length === this.totalRecords;
        this.selectedRows = value;
    }

    onSelectAllChange(event: any) {
        const checked = event.checked;

        if (checked) {
            firstValueFrom(
                this.service.getHistoricalData(this.selectedMonth)
            ).then((res: any) => {
                this.selectedRows = this.convertHistoricalData(res);
                this.selectAllHistory = true;
            });

        } else {
            this.selectedRows = [];
            this.selectAllHistory = false;
        }
    }

    updateData() {
        firstValueFrom(this.service.getFullDashboardData(this.selectedMonth)).then(data => {
            if (data.statistics) {
                this.statistics = data.statistics;

                this.gasCoinOptions = Object.keys(this.statistics.coin_breakdown);
                this.showCoinStats = this.gasCoinOptions.slice();
            }

            if (data.history) {
                this.history = this.convertHistoricalData(data.history);
            }

            if (data.chart) {
                this.chartRaw = data.chart;
                this.graphEmptyState = this.chartRaw.every(day => day.mints <= 0 && day.requests <= 0);

            } else {
                this.graphEmptyState = true;
            }

            this.populateChart();
            
        }).catch(err => {
            console.log("Error fetching full dashboard data:", err);
        });
    }

    linkToBeta() {
        window.open("https://beta.tokenx.dev/", "_blank");
    }

    linkToUpgrade() {
        // window.open("https://beta.tokenx.dev/", "_blank");
    }

    lookupToken(tx: any) {
        const TOKEN_URLS: any = {
            "11155111": "https://sepolia.etherscan.io/nft/{CONTRACT}/{TOKEN}",
            "137": "https://polygonscan.com/token/{CONTRACT}?a={TOKEN}",
            "80002": "https://www.oklink.com/amoy/assets/{CONTRACT}/{TOKEN}",
            "421614": "https://sepolia.arbiscan.io/token/{CONTRACT}?a={TOKEN}",
            "11155420": "https://sepolia-optimistic.etherscan.io/token/{CONTRACT}?a={TOKEN}"
        };

        const url = TOKEN_URLS[tx.chainId]
            .replace("{CONTRACT}", tx.contract_address)
            .replace("{TOKEN}", tx.tokenId);

        window.open(url, "_new");
    }

    toggleVisibility(which: string) {
        this.visibility[which] = !this.visibility[which];
    }

    copyVariable(which: string) {
        navigator.clipboard.writeText(this.variableStorage[which]);

        let varStr: string = "API Key";
        if (which === "secret") {
            varStr = "Secret Key";
        }

        this.store.dispatch(EmitAlertAction({
            message: varStr + " copied to clipboard.",
            alert_type: "success"
        }));
    }

    fetchUserData() {
        firstValueFrom(this.service.getUserData()).then(data => {
            if (data) {
                this.variableStorage.key = data.api_key;
                this.variableStorage.secret = data.api_secret;
            }
        });
    }

    toggleExportDropdown(event: Event, manual?: boolean) {
        event.preventDefault();

        if (manual !== undefined && manual !== null) {
            this.showExportDropdown = manual;

        } else {
            this.showExportDropdown = !this.showExportDropdown;
        }
    }

    export(mode: string) {
        if (!this.history || this.history.length < 1) {
            this.store.dispatch(
                EmitAlertAction({
                    message: "Export Unavailable: No data available for the selected month. Please adjust and try again.",
                    alert_type: "error"
                })
            );

        } else {
            switch(mode) {
                case "csv": {
                    if (this.table) {
                        this.table.exportCSV();
                    }
                    break;
                }
    
                case "pdf": {
                    import('jspdf').then((jsPDF) => {
                        import('jspdf-autotable').then((x) => {
                            const doc = new jsPDF.default('p', 'px', 'a4');
                            (doc as any).autoTable(this.exportColumns, this.history);
                            doc.save('tokenx.pdf');
                        });
                    });
                    break;
                }
            }
        }

        this.showExportDropdown = false;
    }

    sortTable(field: string) {
        this.tableSortField = field;
        this.tableSortOrder = field !== this.tableSortField
            ? this.tableDefaultSort
            : this.tableSortOrder * -1;

        this.loadHistory({
            first: 0,
            rows: this.tableRows,
            sortField: this.tableSortField,
            sortOrder: this.tableSortOrder
        });
    }
}