import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { DashboardComponent } from "./dashboard";
import { LandingPageComponent } from './landing';
import { RegistrationPageComponent } from "./registration-page";
import { ErrorPageComponent } from "./shared/components/error-page";
import { PricingPageComponent } from './pricing';


const routes: Routes = [
    {
        path: "",
        component: LandingPageComponent
    },
    {
        path: "signup",
        canActivate: [AuthGuard],
        component: RegistrationPageComponent
    },
    {
        path: "pricing",
        canActivate: [AuthGuard],
        component: PricingPageComponent
    },
    {
        path: "dashboard",
        canActivate: [AuthGuard],
        component: DashboardComponent
    },
    {
        path: "error",
        component: ErrorPageComponent
    },
    {
        path: "**",
        redirectTo: "error"
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard
    ]
})
export class AppRoutingModule { }
