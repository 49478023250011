import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";


@Injectable()
export class AuthGuard {
    constructor(
        private router: Router,
        private oauthService: OAuthService
    ) {}

    canActivate(): boolean {
        let token = this.oauthService.getIdToken();
        if (token) {
            return true;

        } else {
            this.router.navigateByUrl("");
            return false;
        }
    }
}