import { Pipe, PipeTransform } from "@angular/core";
import { formatCurrency } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import Web3 from "web3";


@Pipe({ name: "usd" })
export class UsdCostPipe implements PipeTransform {
    private web3: any;
    private conversion_rate: number = 2780.02; // Limit requests for testing

    constructor (
        public http: HttpClient
    ) {
        this.web3 = new Web3(window.ethereum);
    }

    transform(cost: number, chain: string = "eth"): Observable<string> {
        if (this.conversion_rate) {
            return of(this.formatted(cost));

        } else {
            // Change based on chain
            let chain_id = "ethereum";
            const url = 'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=' + chain_id;

            return this.http.get(url, {}).pipe(map((response: any) => {
                this.conversion_rate = response[0].current_price;

                return this.formatted(cost);
            }));
        }
    }

    formatted(wei: number): string {
        if (!this.conversion_rate) {
            return "$1 USD";

        } else {
            let eth = parseFloat(this.web3.utils.fromWei(wei, "ether"));
            let converted = eth * this.conversion_rate;

            return formatCurrency(converted, "en", "$") + " USD";
        }
    }

    transformStatic(cost: number): string {
        if (!this.conversion_rate) {
            this.transform(cost);
        }

        return this.formatted(cost);
    }
}