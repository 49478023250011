import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";

import { BaseService } from "../shared/services";

@Injectable()
export class DashboardService extends BaseService {
    constructor(
        http: HttpClient,
        oauthService: OAuthService
    ) {
        super(http, oauthService);
    }

    private addDateRange(
        url: string,
        start: Date = null,
        end: Date = null
    ) {
        let temp = url;

        if (start) {
            temp += `?startDate=${start.toLocaleDateString()}`;
        }

        if (end) {
            temp += `&endDate=${end.toLocaleDateString()}`;
        }

        return temp;
    }

    private addDate(
        url: string,
        date: Date
    ) {
        let temp = url;
        temp += `?date=${date.toLocaleDateString()}`;

        return temp;
    }

    getUserData() {
        return this.makeRequest({
            method: BaseService.GET,
            url: "api/tac/v1/dashboard/userdata"
        });
    }

    getLimits() {
        return this.makeRequest({
            method: BaseService.GET,
            url: "api/tac/v1/dashboard/userlimits"
        });
    }

    getChartData(date: Date = null) {
        let url = "api/tac/v1/dashboard/chartdata";

        if (date) {
            url = this.addDate(url, date);
        }

        return this.makeRequest({
            method: BaseService.GET,
            url: url
        });
    }

    getStatistics(date: Date = null) {
        let url = "api/tac/v1/dashboard/statistics";

        if (date) {
            url = this.addDate(url, date);
        }

        return this.makeRequest({
            method: BaseService.GET,
            url: url
        });
    }

    getHistoricalData(date: Date = null, params: any = null) {
        let url = "api/tac/v1/dashboard/history";

        if (date) {
            url = this.addDate(url, date);
        }

        if (params) {
            if (url.includes("?")) {
                url += "&"
            } else {
                url += "?"
            }

            url += "filter=";
            url += JSON.stringify(params);
        }

        return this.makeRequest({
            method: BaseService.GET,
            url: url
        });
    }

    getFullDashboardData(date: Date = new Date()) {
        let url = "api/tac/v1/dashboard/full";

        if (date) {
            url = this.addDate(url, date);
        }

        return this.makeRequest({
            method: BaseService.GET,
            url: url
        });
    }
}