import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OAuthModule } from "angular-oauth2-oidc";

import { reducers } from "./store";
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { LayoutService } from './shared/services';
import { LayoutEffects } from './layout/layout.effects';
import { HeaderComponent } from './header';
import { DashboardComponent, DashboardService } from './dashboard';
import { UserService, UserEffects } from "./user";
import { AlertsComponent } from './shared/components';
import { LandingPageComponent } from './landing';
import { RegistrationPageComponent, RegistrationService } from "./registration-page";
import { ErrorPageComponent } from './shared/components/error-page';
import { PricingPageComponent } from './pricing';
import { ChargebeeService } from "./shared/services/chargebee.service";

const APP_SERVICES: any[] = [
    LayoutService,
    UserService,
    DashboardService,
    RegistrationService,
    ChargebeeService
];


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        DashboardComponent,
        AlertsComponent,
        LandingPageComponent,
        RegistrationPageComponent,
        ErrorPageComponent,
        PricingPageComponent
    ],
    imports: [
        EffectsModule.forRoot([
            LayoutEffects,
            UserEffects
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        StoreModule.forRoot(reducers),
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        OAuthModule.forRoot()
    ],
    providers: [
        ...APP_SERVICES
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
