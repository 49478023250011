import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";

import { BaseService } from "../shared/services/base.service";


@Injectable()
export class RegistrationService extends BaseService {
    constructor(
        http: HttpClient,
        oauthService: OAuthService
    ) {
        super(http, oauthService);
    }

    registerUser(user: any) {
        let url = "api/tac/v1/user/register";

        return this.makeRequest({
            method: BaseService.POST,
            url: url,
            body: user
        });
    }
}