<div class="page-title">
    Usage
</div>

<div class="selectors-con">
    <div class="date-picker-con">
        <p-calendar
            [(ngModel)]="selectedMonth"
            appendTo="body"
            view="month"
            dateFormat="MM yy"
            [maxDate]="maxDate"
            [readonlyInput]="true"
            (onSelect)="updateData()"
        ></p-calendar>
    </div>

    <div class="export-con">
        <p-button label="Export"
            icon="mdi mdi-export-variant"
            (onClick)="toggleExportDropdown($event, true)"
        ></p-button>

        <div class="export-dropdown animated fadeInDown"
            [class.hidden]="!showExportDropdown"
        >
            <ul>
                <li (click)="export('csv')">
                    Export as CSV
                </li>

                <!-- <li (click)="export('pdf')">
                    Export as PDF
                </li> -->
            </ul>
        </div>
    </div>
</div>

<div class="header-cards-con">
    <!-- <div class="quickstart">
        <div class="left">
            <i class="mdi mdi-text-box-outline"></i>
            Quickstart Guide
        </div>

        <div class="right-con">
            <i class="mdi mdi-chevron-right"></i>
        </div>
    </div> -->

    <div class="demos" (click)="linkToBeta()">
        <div class="left">
            <i class="mdi mdi-bullseye"></i>
            Example Demos
        </div>

        <div class="right-con">
            <i class="mdi mdi-chevron-right"></i>
        </div>
    </div>

    <div class="api-key">
        <div class="card-title">
            API Key
        </div>

        <div class="card-contents">
            <span class="secret" [class.obfuscated]="!visibility['key']">
                {{ variableStorage["key"] }}
            </span>

            <div class="buttons">
                <i class="mdi" [ngClass]="{
                        'mdi-eye': !visibility['key'],
                        'mdi-eye-off': visibility['key']
                    }"
                    (click)="toggleVisibility('key')"
                ></i>

                <i class="mdi mdi-content-copy" (click)="copyVariable('key')"></i>
            </div>
        </div>
    </div>

    <div class="api-secret">
        <div class="card-title">
            Secret Key
        </div>

        <div class="card-contents">
            <span class="secret" [class.obfuscated]="!visibility['secret']">
                {{ variableStorage["secret"] }}
            </span>

            <div class="buttons">
                <i class="mdi" [ngClass]="{
                        'mdi-eye': !visibility['secret'],
                        'mdi-eye-off': visibility['secret']
                    }"
                    (click)="toggleVisibility('secret')"
                ></i>

                <i class="mdi mdi-content-copy" (click)="copyVariable('secret')"></i>
            </div>
        </div>
    </div>
</div>

<div *ngIf="graphEmptyState" class="chart-con-empty"
    [style.background-image]="'url(\'../../assets/images/empty-states/'+theme+'/usage.png\')'"
>
    <div class="text">
        No Data Available
    </div>
</div>

<div *ngIf="!graphEmptyState" class="chart-con">
    <p-chart type="line" [data]="chartData" [options]="chartOptions"></p-chart>
</div>

<div class="stats-row">
    <div class="gas-usage">
        <div class="label">
            GAS Usage
        </div>

        <div class="card">
            <div class="card-left">
                <div class="row-top">
                    <div class="icon">
                        <i class="mdi mdi-barrel-outline"></i>
                    </div>
        
                    <div class="count">
                        {{ statistics["gas_usd"] | currency: "USD" }}
        
                        <span class="currency">
                            USD TOTAL
                        </span>
                    </div>
                </div>

                <div class="row-bottom">
                    <p-multiSelect
                        appendTo="body"
                        [options]="gasCoinOptions"
                        [(ngModel)]="showCoinStats"
                        [showHeader]="false"
                        placeholder="Show Blockchains"
                        styleClass="chain-selector"
                    >
                        <ng-template let-value pTemplate="selectedItems">
                            Show Blockchains
                        </ng-template>
                    </p-multiSelect>
                </div>
            </div>

            <div class="card-right">
                <div class="conversion">
                    <div *ngFor="let coin of showCoinStats" class="coin-row">
                        {{ statistics["coin_breakdown"][coin] }} {{ coin }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mints-con">
        <div class="label">
            Mints
        </div>

        <div *ngIf="statistics['mints'] < maximums['mints']"
            class="card"
            [class.usage-warning]="statistics['mints'] >= (maximums['mints'] * 0.7)"
        >
            <div class="icon">
                <i class="mdi mdi-circle-multiple-outline"></i>
            </div>

            <div *ngIf="statistics['mints'] >= (maximums['mints'] * 0.7)"
                class="usage-warning"
                [class.elevated]="statistics['mints'] >= (maximums['mints'] * 0.9)"
            >
                {{ statistics['mints'] | approxPercent: maximums['mints'] }}% Used
            </div>

            <div class="count">
                {{ statistics['mints'] | number }}
            </div>

            <div class="max">
                / {{ maximums['mints'] | number }} limit
            </div>

            <!-- <div *ngIf="statistics['mints'] >= (maximums['mints'] * 0.7)" class="upgrade-con">
                <i class="mdi mdi-lightning-bolt"></i>
                Upgrade Now
            </div> -->

            <div class="progress">
                <p-progressBar
                    [value]="(statistics['mints'] * 100) / maximums['mints']"
                    [showValue]="false"
                    [style]="{ 'height': '15px' }"
                ></p-progressBar>
            </div>
        </div>

        <div *ngIf="statistics['mints'] >= maximums['mints']"
            class="card link-card"
            (click)="linkToUpgrade()"
        >
            <div class="side-bar"></div>

            <div class="content">
                <div class="icon-con">
                    <i class="mdi mdi-alert-circle"></i>
                </div>

                <div class="center-content">
                    <div class="limit">
                        {{ maximums['mints'] | number }} Limit Hit
                    </div>

                    <div class="tagline">
                        Click here to upgrade for more
                    </div>
                </div>
            </div>

            <div class="export-icon">
                <i class="mdi mdi-export-variant"></i>
            </div>

            <div class="full-progress"></div>
        </div>
    </div>

    <div class="requests-con">
        <div class="label">
            API Requests
        </div>

        <div *ngIf="statistics['requests'] < maximums['requests']"
            class="card"
            [class.usage-warning]="statistics['requests'] >= (maximums['requests'] * 0.7)"
        >
            <div class="icon">
                <span class="material-symbols-outlined">api</span>
            </div>

            <div *ngIf="statistics['requests'] >= (maximums['requests'] * 0.7)"
                class="usage-warning"
                [class.elevated]="statistics['requests'] >= (maximums['requests'] * 0.9)"
            >
                {{ statistics['requests'] | approxPercent: maximums['requests'] }}% Used
            </div>

            <div class="count">
                {{ statistics['requests'] | number }}
            </div>

            <div class="max">
                / {{ maximums['requests'] | number }} limit
            </div>

            <!-- <div *ngIf="statistics['requests'] >= (maximums['requests'] * 0.7)" class="upgrade-con">
                <i class="mdi mdi-lightning-bolt"></i>
                Upgrade Now
            </div> -->

            <div class="progress">
                <p-progressBar
                    [value]="(statistics['requests'] * 100) / maximums['requests']"
                    [showValue]="false"
                    [style]="{ 'height': '15px' }"
                ></p-progressBar>
            </div>
        </div>

        <div *ngIf="statistics['requests'] >= maximums['requests']"
            class="card link-card"
            (click)="linkToUpgrade()"
        >
            <div class="side-bar"></div>

            <div class="content">
                <div class="icon-con">
                    <i class="mdi mdi-alert-circle"></i>
                </div>

                <div class="center-content">
                    <div class="limit">
                        {{ maximums['requests'] | number }} Limit Hit
                    </div>

                    <div class="tagline">
                        Click here to upgrade for more
                    </div>
                </div>
            </div>

            <div class="export-icon">
                <i class="mdi mdi-export-variant"></i>
            </div>

            <div class="full-progress"></div>
        </div>
    </div>
</div>

<div class="history-con">
    <div class="section-title">
        GAS Usage
    </div>

    <p-table
        *ngIf="history.length"
        [value]="history"
        [paginator]="true"
        [rows]="tableRows"
        [lazy]="true"
        (onLazyLoad)="loadHistory($event)"
        [columns]="tableColumns"
        selectionMode="multiple"
        [selection]="selectedRows"
        (selectionChange)="onSelectionChange($event)"
        [selectAll]="selectAllHistory"
        (selectAllChange)="onSelectAllChange($event)"
        [sortField]="tableSortField"
        [sortOrder]="tableSortOrder"
        [defaultSortOrder]="tableDefaultSort"
        [totalRecords]="totalRecords"
        exportFilename="tokenx"
        [loading]="tableLoading"
    >
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th>
                    <span class="action-span" (click)="sortTable('token_id')">
                        Token ID
                        <p-sortIcon field="token_id"></p-sortIcon>
                    </span>
                </th>

                <th>
                    <span class="action-span" (click)="sortTable('chain')">
                        Chain
                        <p-sortIcon field="chain"></p-sortIcon>
                    </span>
                </th>

                <th>
                    <span class="action-span" (click)="sortTable('coin_cost')">
                        GAS Fee
                        <p-sortIcon field="coin_cost"></p-sortIcon>
                    </span>
                </th>

                <th>
                    <span class="action-span" (click)="sortTable('usd_cost')">
                        USD Equivalent
                        <p-sortIcon field="usd_cost"></p-sortIcon>
                    </span>
                </th>

                <th>
                    <span class="action-span" (click)="sortTable('date')">
                        Used On
                        <p-sortIcon field="date"></p-sortIcon>
                    </span>

                    <div class="utc-switcher-con">
                        <p-toggleButton
                            [(ngModel)]="showUTCDate"
                            onLabel="Show Local"
                            offLabel="Show UTC"
                            styleClass="utc-switcher"
                        ></p-toggleButton>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tx let-columns="columns">
            <tr [pSelectableRow]="tx">
                <td>
                    <!-- <span (click)="lookupToken(tx)"> -->
                    <span>
                        {{ tx["tokenId"] }}
                    </span>
                </td>

                <td>
                    <span>
                        {{ tx["chain"] }}
                    </span>
                </td>

                <td>
                    <span [pTooltip]="tx['coinCost']"
                        tooltipPosition="bottom"
                        tooltipStyleClass="no-break"
                    >
                        {{ tx["coinCostShort"] }}
                    </span>
                </td>

                <td>
                    <span>
                        {{ tx["usdCost"] }}
                    </span>
                </td>

                <td>
                    <span>
                        <ng-container *ngIf="showUTCDate">
                            {{ tx.dateUTC }}
                        </ng-container>

                        <ng-container *ngIf="!showUTCDate">
                            {{ tx.date | date: "MM-dd-yy h:mm a" }}
                        </ng-container>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="!history?.length" class="history-empty"
        [style.background-image]="'url(\'../../assets/images/empty-states/'+theme+'/gasmode.png\')'"
    >
        <div class="text">
            No Data Available
        </div>
    </div>
</div>