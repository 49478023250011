import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { Store } from "@ngrx/store";
import { firstValueFrom } from "rxjs";

import { AppStore } from "../store";
import { EmitAlertAction } from "../shared";
import { RegistrationService } from "./registration.service";
import { UserUpdateAction } from "../user";


@Component({
    selector: "registration-page",
    templateUrl: "./registration-page.component.html",
    styleUrls: ["./registration-page.component.scss"]
})
export class RegistrationPageComponent implements OnInit {
    public form: FormGroup = new FormGroup({});

    constructor(
        private store: Store<AppStore>,
        private router: Router,
        private oauthService: OAuthService,
        private service: RegistrationService
    ) {}

    ngOnInit() {
        this.buildForm();
    }

    buildForm() {
        this.form = new FormGroup({
            first_name: new FormControl<string>("", Validators.required),
            last_name: new FormControl<string>("", Validators.required),
            company: new FormControl<string>("", Validators.required),
            phone: new FormControl<string>("", Validators.required),
            emailConsent: new FormControl<boolean>(true),
            tos: new FormControl<boolean>(false, Validators.required)
        });
    }

    register() {
        if (!this.form.valid) {
            this.store.dispatch(EmitAlertAction({
                message: "Please fill in all required fields.",
                alert_type: "info"
            }));
            return;
        }

        if (!this.form.value["tos"]) {
            this.store.dispatch(EmitAlertAction({
                message: "Please agree to the Terms of Service before proceeding.",
                alert_type: "info"
            }));
            return;
        }

        let body = {
            id_token: this.oauthService.getIdToken(),
            first_name: this.form.value["first_name"],
            last_name: this.form.value["last_name"],
            company_name: this.form.value["company"],
            phone: this.form.value["phone"],
            emailConsent: this.form.value["emailConsent"]
        };

        firstValueFrom(this.service.registerUser(body)).then(res => {
            this.store.dispatch(UserUpdateAction(res));
            this.router.navigateByUrl("pricing");

        }).catch(err => {
            console.error("Registration error:", err);

            this.store.dispatch(EmitAlertAction({
                message: "There was an error trying to register your information. Please try again later.",
                alert_type: "error"
            }));
        });
    }
}