import { createReducer, on } from "@ngrx/store";
import * as UserActions from './user.actions';
import { jwtDecode } from "jwt-decode";

export interface UserState {
    user: any;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    company: string;
};

const initialState: UserState = <UserState> {
    user: {},
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    company: ""
};

export const userReducer = createReducer(
    initialState,
    on(
        UserActions.UserLogoutAction,
        (state, action) => ({
            ...state,
            ...initialState
        })
    ),
    on(
        UserActions.DecodeUserInfoAction,
        (state, action) => {
            const decoded: any = jwtDecode(action.payload);
            return {
                ...state,
                user: decoded,
                email: decoded.email
            };
        }
    ),
    on(
        UserActions.UserUpdateAction,
        (state, action) => ({
            ...state,
            ...action.payload
        })
    ),
    on(
        UserActions.FetchUserInfoSuccessAction,
        (state, action) => ({
            ...state,
            ...action.payload.body
        })
    )
);