<div *ngIf="planType !== 'success'" class="plans-body">
    <div class="pricing-header">
        <div class="header-title">
            Plans & Pricing
        </div>

        <div class="header-tagline">
            {{ planConfigs[planType].header }}
        </div>
    </div>

    <div class="cards-con">
        <div *ngFor="let plan of planConfigs[planType].plans"
            class="card-wrapper"
            [class.best-value]="plan.bestValue"
        >
            <div *ngIf="plan.bestValue" class="value-header">
                🔖 Best Value
            </div>

            <div class="card">
                <div class="card-title">
                    {{ plan.name }}
                </div>

                <div *ngIf="plan.price" class="price-con">
                    <div class="price">
                        {{ plan.price }}
                    </div>

                    <div class="frequency">
                        {{ plan.frequency }}
                    </div>
                </div>

                <div *ngIf="plan.image" class="price-con">
                    <div class="image-con">
                        {{ plan.image }}
                    </div>
                </div>

                <div class="button-con">
                    <p-button [label]="plan.buttonLabel"
                        [style]="{ 'width': '100%' }"
                        (onClick)="plan.buttonCommand()"
                    ></p-button>
                </div>

                <div class="checklist-con">
                    <div *ngIf="plan.checklistHeader" class="checklist-header">
                        {{ plan.checklistHeader }}
                    </div>

                    <ul>
                        <li *ngFor="let item of plan.checklistItems">
                            <strong>✓</strong>
                            {{ item }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="planType === 'success'" class="success-body">
    <div class="success-header">
        Success! Your subscription has been created successfully.
    </div>

    <div class="button-con">
        <p-button
            label="Go to Dashboard"
            (onClick)="goToDashboard()"
        ></p-button>
    </div>
</div>
