import { Pipe, PipeTransform } from "@angular/core";

import { BLOCKCHAIN_OPTIONS, BlockchainModel } from "../utilities/constants";


@Pipe({ name: "chainId" })
export class ChainIdPipe implements PipeTransform {
    constructor () {}

    transform(id: string, mode: keyof BlockchainModel = "name"): string | boolean {
        let mapping = BLOCKCHAIN_OPTIONS.find(chain => chain.chainId === id);

        if (!mapping) {
            return id;
        }

        return mapping[mode] || id;
    }
}