export const MOBILE_CUTOFF_WIDTH: number = 640; // in px

export const enum RequestMethod {
    "POST",
    "GET",
    "PUT",
    "PATCH",
    "DELETE"
};

export const enum ResponseType {
    "BLOB" = "blob",
    "ARRAY_BUFFER" = "arraybuffer",
    "TEXT" = "text"
};

export function toPayload<T> (arg: T) {
    return { payload: arg };
};

export const INFURA_BASE_URL = "https://ls.infura-ipfs.io/ipfs/";
export const CONTRACT_ABI_JSON = "/contracts/LSTokenX.json";

export class BlockchainModel {
    name: string;
    chainId: string;
    coin: string;
    coin_short: string;
    icon?: string;
    url?: string;
    tokenUrl?: string;
    selectable: boolean = false;
};
export const DEFAULT_BLOCKCHAIN = "80002"; // Polygon Testnet Amoy
export const BLOCKCHAIN_OPTIONS = [
    {
        name: "Ethereum Mainnet",
        chainId: "1",
        coin: "Ethereum",
        coin_short: "ETH",
        icon: "assets/images/icons/eth-icon.png",
        url: "",
        tokenUrl: "",
        selectable: false
    },
    {
        name: "Ethereum Testnet",
        chainId: "11155111",
        coin: "Ether",
        coin_short: "ETH",
        icon: "assets/images/icons/eth-icon.png",
        url: "https://sepolia.etherscan.io",
        tokenUrl: "https://sepolia.etherscan.io/nft/0x4a1d383ece83c62854673d9d0a64b1efc4161951/",
        selectable: true
    },
    {
        name: "Polygon Mainnet",
        chainId: "137",
        coin: "MATIC",
        coin_short: "MATIC",
        icon: "assets/images/icons/polygon-icon.png",
        url: "",
        tokenUrl: "",
        selectable: false
    },
    {
        name: "Polygon Testnet",
        chainId: "80001",
        coin: "MATIC",
        coin_short: "MATIC",
        icon: "assets/images/icons/polygon-icon.png",
        url: "https://mumbai.polygonscan.com",
        tokenUrl: "https://mumbai.polygonscan.com/token/0x62dbbedd28d2cc3a3c05e39dc97da8aca50e06d2?a=",
        selectable: false
    },
    {
        name: "Polygon Testnet",
        chainId: "80002",
        coin: "MATIC",
        coin_short: "MATIC",
        icon: "assets/images/icons/polygon-icon.png",
        url: "https://www.oklink.com/amoy",
        tokenUrl: "https://www.oklink.com/amoy/assets/0x02df48be44a51ceb0c05edc2552e66bda3309146/",
        selectable: true
    },
    {
        name: "Arbitrum Testnet",
        chainId: "421614",
        coin: "Ether",
        coin_short: "ETH",
        icon: "assets/images/icons/arbitrum-icon.png",
        url: "https://sepolia.arbiscan.io",
        tokenUrl: "https://sepolia.arbiscan.io/token/0x4a1D383eCE83C62854673d9D0A64B1efC4161951/",
        selectable: false
    },
    {
        name: "Optimism Testnet",
        chainId: "11155420",
        coin: "Ether",
        coin_short: "ETH",
        icon: "assets/images/icons/optimism-logo.png",
        url: "https://sepolia-optimistic.etherscan.io",
        tokenUrl: "https://sepolia-optimistic.etherscan.io/address/0x4a1D383eCE83C62854673d9D0A64B1efC4161951/",
        selectable: false
    }
];