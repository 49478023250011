import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { AppStore } from "../store";
import { getTheme } from "../layout";
import { FetchUserInfoAction } from "../user";


@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public currentTheme: string;

    constructor(
        private store: Store<AppStore>
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.store.select(getTheme).subscribe(theme => {
                this.currentTheme = theme;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    checkUserState() {
        this.store.dispatch(FetchUserInfoAction());
    }
}