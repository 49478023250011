import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";

export const UserActionTypes = {
    USER_LOGOUT: "[User] User Logout",
    DECODE_USER_INFO: "[User] Decode User Info",
    USER_UPDATE: "[User] User Update",
    FETCH_USER_INFO: "[User] Fetch User Info",
    FETCH_USER_INFO_SUCCESS: "[User] Fetch User Info Success",
    FETCH_USER_INFO_FAILURE: "[User] Fetch User Info Failure"
};

export const UserLogoutAction = createAction(
    UserActionTypes.USER_LOGOUT
);

export const DecodeUserInfoAction = createAction(
    UserActionTypes.DECODE_USER_INFO,
    (token: any) => ({ payload: token })
);

export const UserUpdateAction = createAction(
    UserActionTypes.USER_UPDATE,
    (user: any) => ({ payload: user })
);

export const FetchUserInfoAction = createAction(
    UserActionTypes.FETCH_USER_INFO
);

export const FetchUserInfoSuccessAction = createAction(
    UserActionTypes.FETCH_USER_INFO_SUCCESS,
    (info: any) => ({ payload: info })
);

export const FetchUserInfoFailureAction = createAction(
    UserActionTypes.FETCH_USER_INFO_FAILURE,
    (err: HttpErrorResponse) => ({ payload: err })
);