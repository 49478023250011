import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { OAuthService } from "angular-oauth2-oidc";

import { environment } from "../../../environments/environment";
import { BaseService } from "./base.service";


@Injectable()
export class ChargebeeService extends BaseService {
    private chargebee: any;

    constructor(
        http: HttpClient,
        oauthService: OAuthService
    ) {
        super(http, oauthService);

        this.chargebee = Chargebee.init({
            site: environment.chargebee_site_id,
        });
    }

    getInstance() {
        return this.chargebee;
    }

    getPortalSession() {
        return this.makeRequest({
            url: "api/tac/v1/user/portal-session",
            method: BaseService.GET
        });
    }

    createHostedPage(planId: string, quantity: number = 1): Observable<any> {
        return this.makeRequest({
            url: "api/tac/v1/chargebee/checkout",
            method: BaseService.POST,
            body: {
                plan_id: planId,
                quantity: quantity
            }
        });
    }

    // openCheckout(hostedPageId: string, successCallback: Function) {
    //     this.chargebee.openCheckout({
    //         hostedPage: function() {
    //             return {
    //                 id: hostedPageId
    //             };
    //         },
    //         success: successCallback,
    //         close: function() {
    //             console.log("Checkout closed");
    //         },
    //         step: function(step: any) {
    //             console.log("Checkout step:", step);
    //         }
    //     });
    // }
}