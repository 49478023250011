<div class="login-con">
    <div *ngIf='userName'
        class="user-profile"
        (click)="toggleDropdown($event)"
    >
        <i class="mdi mdi-account-circle-outline user-icon"></i>

        <div class="name">
            {{ userName }}
        </div>

        <i class="mdi mdi-menu-down"></i>
    </div>

    <ul class="logout-dropdown animated fadeInDown"
        [class.hidden]="!showDropdown"
    >
        <li *ngFor="let item of menuItems" (click)="item.command($event)">
            <i [ngClass]="item.icon"></i>
            {{ item.label }}
        </li>
    </ul>
    
    <div *ngIf='!userName' class="sign-in" (click)="loginUser()">
        <i class="mdi mdi-account-circle-outline user-icon"></i>
        Login
    </div>
</div>