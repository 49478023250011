<div class="header-con">
    <div class="left">
        <a (click)="checkUserState()">
            <img id="header-logo" class="logo"
                [src]="currentTheme === 'light'
                    ? 'assets/images/tokenx-logo.png'
                    : 'assets/images/tokenx-logo-dark.png'"
            >
        </a>
    </div>

    <div class="title"></div>

    <div class="right">
        <theme-switcher></theme-switcher>

        <login></login>
    </div>
</div>
