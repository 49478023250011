import { Component } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from "angular-oauth2-oidc";

import { environment } from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'app';

    constructor(
        private oAuthService: OAuthService
    ) {
        // Ensure oAuthConfig gets set correctly, even on refresh
        const noDiscoveryAuthConfigObj: AuthConfig = environment.authConfig;
        this.oAuthService.configure(noDiscoveryAuthConfigObj);
        this.oAuthService.setStorage(sessionStorage);
        this.oAuthService.tokenValidationHandler = new NullValidationHandler();
        this.oAuthService.setupAutomaticSilentRefresh();
    }
}
