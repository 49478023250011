import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription, firstValueFrom } from "rxjs";
import { filter } from "rxjs/operators";
import { OAuthService } from "angular-oauth2-oidc";


import { AppStore } from "../../../store";
import { FetchUserInfoAction, DecodeUserInfoAction, UserLogoutAction } from '../../../user/user.actions';
import { getUserName } from "../../../user/user.selectors";
import { ChargebeeService } from "../../services";


@Component({
    selector: "login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private chargebee: any;

    public userName: string;
    public showDropdown: boolean = false;
    public menuItems: any[] = [];

    constructor(
        private store: Store<AppStore>,
        private renderer: Renderer2,
        private oauthService: OAuthService,
        private cbService: ChargebeeService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.chargebee = this.cbService.getInstance();

        this.subscriptions.push(
            this.route.queryParams.subscribe(params => {
                if (params["code"]) {
                    this.initLoginFlow();

                } else {
                    this.store.dispatch(FetchUserInfoAction());
                }
            })
        );

        this.subscriptions.push(
            this.store.select(getUserName).subscribe(name => {
                this.userName = name;
            })
        );

        this.subscriptions.push(
            this.router.events.pipe(
                filter((e): e is NavigationEnd => e instanceof NavigationEnd)
            ).subscribe(e => {
                this.formMenuItems(e.url);
            })
        );

        
        this.formMenuItems(window.location.href);
    }

    ngAfterViewInit() {
        this.renderer.listen("body", "click", event => {
            if (!event.defaultPrevented) {
                this.showDropdown = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.oauthService.getAccessToken()) {
            this.logout();
        }

        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    loginUser() {
        this.initLoginFlow();
    }

    private initLoginFlow() {
        this.oauthService
            .loadDiscoveryDocumentAndLogin()
            .then(response => {
                if (this.oauthService.hasValidIdToken()) {
                    this.store.dispatch(DecodeUserInfoAction(this.oauthService.getIdToken()));

                    window.setTimeout(() => {
                        // Need client_id to propagate to session storage before fetching user info
                        this.store.dispatch(FetchUserInfoAction());
                    });
                }

            }).catch(error => {
                // if (window.location.pathname.split("/").pop() !== "") {
                //     // Redirect user to url of login page (hosted)
                //     window.location.href = window.location.origin;
                // }
            });
    }

    toggleDropdown(event: Event) {
        event.preventDefault();
        
        this.showDropdown = !this.showDropdown;
    }

    formMenuItems(url: string) {
        this.menuItems = [];

        if (url.endsWith("/dashboard")) {
            this.menuItems.push({
                id: "account",
                label: "Update Subscription",
                icon: "mdi mdi-account",
                command: (event: Event) => {
                    firstValueFrom(this.cbService.getPortalSession())
                        .then(response => {
                            const sessionId = response.token;
                            this.chargebee.setPortalSession(sessionId);
                            let cbPortal = this.chargebee.createChargebeePortal();

                            cbPortal.open({
                                close() {
                                    // close callbacks
                                }
                            });
                        });
                }
            });
        }

        this.menuItems.push({
            id: "logout",
            label: "Logout",
            icon: "mdi mdi-logout",
            command: (event: Event) => {
                this.logout(event);
            }
        });
    }

    logout(event?: Event) {
        if (event) {
            event.preventDefault();
        }

        this.store.dispatch(UserLogoutAction());
    }
}