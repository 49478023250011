<div class="wrapper">
    <div class="left-con">
        <div class="image-con">
            <img src="assets/images/tokenx-d.png">
        </div>

        <div class="form-con" (keydown.enter)="register()">
            <form [formGroup]="form">
                <div class="row">
                    <label>First Name</label>
                    <input pInputText
                        type="text"
                        formControlName="first_name"
                        placeholder="First Name"
                    />
                </div>

                <div class="row">
                    <label>Last Name</label>
                    <input pInputText
                        type="text"
                        formControlName="last_name"
                        placeholder="Last Name"
                    />
                </div>

                <div class="row">
                    <label>Company Name</label>
                    <input pInputText
                        type="text"
                        formControlName="company"
                        placeholder="Company Name"
                    />
                </div>

                <div class="row">
                    <label>Phone Number</label>
                    <input pInputText
                        type="text"
                        formControlName="phone"
                        placeholder="Phone"
                    />
                </div>

                <div class="consent">
                    <p-checkbox formControlName="emailConsent" [binary]="true" inputId="ec"></p-checkbox>
                    <label for="ec">
                        Email me with news and offers
                    </label>
                </div>

                <div class="consent">
                    <p-checkbox formControlName="tos" [binary]="true" inputId="tos"></p-checkbox>
                    <label for="tos">
                        I Agree to the
                        <a href="https://www.lifesite.co/terms-of-service/" target="_blank">Terms of Service</a>
                        and
                        <a href="https://www.lifesite.co/privacy-policy/" target="_blank">Privacy Policy</a>
                    </label>
                </div>
            </form>
        </div>

        <div class="actions-con">
            <p-button
                label="Continue"
                [style]="{ 'width': '100%' }"
                (onClick)="register()"
            ></p-button>
        </div>
    </div>

    <div class="img-con">
        <img src="assets/images/rubix.png">
    </div>
</div>
