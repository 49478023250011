import { ActionReducerMap } from "@ngrx/store";

import { LayoutState, layoutReducer } from "./layout/layout.reducer";
import { UserState, userReducer } from "./user";
import { AlertState, alertReducer } from "./shared/components/alerts";


export interface AppStore {
    layout: LayoutState,
    user: UserState,
    alerts: AlertState
};

export const reducers: ActionReducerMap<AppStore> = {
    layout: layoutReducer,
    user: userReducer,
    alerts: alertReducer
};