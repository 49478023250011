import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription, firstValueFrom } from "rxjs";

import { AppStore } from "../store";
import { ChargebeeService } from "../shared/services";
import { EmitAlertAction } from "../shared";


@Component({
    selector: "pricing-page",
    templateUrl: "./pricing.component.html",
    styleUrls: ["./pricing.component.scss"]
})
export class PricingPageComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private chargebee: any;

    public planType: string = "personal";
    public planConfigs: any;

    constructor(
        private store: Store<AppStore>,
        private router: Router,
        private cbService: ChargebeeService
    ) {}

    ngOnInit(): void {
        this.chargebee = this.cbService.getInstance();

        this.planConfigs = {
            personal: {
                header: "For Individuals",
                plans: [
                    {
                        bestValue: false,
                        name: "Light",
                        price: "$9",
                        frequency: "per month",
                        buttonLabel: "Buy Now",
                        buttonCommand: () => {
                            this.goToCheckout('veriseal-personal-light-USD-Monthly');
                        },
                        checklistHeader: "",
                        checklistItems: [
                            "Up to 20 Scans",
                            "1 scanning device",
                            "1 Wallet",
                            "1 Admin User",
                            "Files up to 5 MB",
                            "MATIC Blockchain support"
                        ]
                    },
                    {
                        bestValue: false,
                        name: "Standard",
                        price: "$27",
                        frequency: "per month",
                        buttonLabel: "Buy Now",
                        buttonCommand: () => {
                            this.goToCheckout('veriseal-personal-standard-USD-Monthly');
                        },
                        checklistHeader: "Everything in Light and...",
                        checklistItems: [
                            "Up to 100 Scans",
                            "MATIC & ETH Blockchain support"
                        ]
                    },
                    {
                        bestValue: true,
                        name: "Premium",
                        price: "$56",
                        frequency: "per month",
                        buttonLabel: "Buy Now",
                        buttonCommand: () => {
                            this.goToCheckout('veriseal-personal-premium-USD-Monthly');
                        },
                        checklistHeader: "Everything in Standard and...",
                        checklistItems: [
                            "Up to 350 Scans",
                            "5 scanning devices",
                            "5 Wallets"
                        ]
                    },
                    {
                        bestValue: false,
                        name: "Business",
                        image: "🏢",
                        buttonLabel: "See Business Plans",
                        buttonCommand: () => {
                            this.switchPlans('business');
                        },
                        checklistHeader: "Everything in Premium and...",
                        checklistItems: [
                            "Business-wide deployment",
                            "Unified admin and billing",
                            "Priority Support",
                            "More Scans",
                            "More Devices",
                            "More Wallets",
                            "More Blockchains",
                            "Larger file sizes",
                            "Multiple logins (coming soon! 🎉)"
                        ]
                    },
                    {
                        bestValue: false,
                        name: "Enterprise",
                        image: "🏙️",
                        buttonLabel: "Contact Sales",
                        buttonCommand: () => {
                            this.followLink('https://www.tokenx.is/#home-contact');
                        },
                        checklistHeader: "Everything in Business and...",
                        checklistItems: [
                            "Organization-wide deployment",
                            "Advanced reporting and analytics tools",
                            "API access",
                            "Early access to new product features",
                            "Flexible payment options"
                        ]
                    }
                ]
            },
            business: {
                header: "For Businesses",
                plans: [
                    {
                        bestValue: false,
                        name: "Business Light",
                        price: "$79",
                        frequency: "per month",
                        buttonLabel: "Buy Now",
                        buttonCommand: () => {
                            this.goToCheckout('veriseal-business-light-USD-Monthly');
                        },
                        checklistHeader: "",
                        checklistItems: [
                            "Up to 500 Scans",
                            "5 scanning devices",
                            "5 Wallets",
                            "Files up to 500 MB",
                            "MATIC, ETH, ARB Blockchain support",
                            "Business-wide deployment",
                            "Unified admin and billing",
                            "Priority Support",
                            "Multiple logins (coming soon! 🎉)"
                        ]
                    },
                    {
                        bestValue: false,
                        name: "Business Standard",
                        price: "$325",
                        frequency: "per month",
                        buttonLabel: "Buy Now",
                        buttonCommand: () => {
                            this.goToCheckout('veriseal-business-standard-USD-Monthly');
                        },
                        checklistHeader: "Everything in Business Light and...",
                        checklistItems: [
                            "Up to 2,000 Scans",
                            "12 Scanning Devices"
                        ]
                    },
                    {
                        bestValue: true,
                        name: "Business Premium",
                        price: "$750",
                        frequency: "per month",
                        buttonLabel: "Buy Now",
                        buttonCommand: () => {
                            this.goToCheckout('veriseal-business-premium-USD-Monthly');
                        },
                        checklistHeader: "Everything in Business Standard and...",
                        checklistItems: [
                            "Up to 5,000 Scans",
                            "50 Scanning Devices",
                            "Files up to 1 GB"
                        ]
                    },
                    {
                        bestValue: false,
                        name: "Personal",
                        image: "👤",
                        buttonLabel: "See Personal Plans",
                        buttonCommand: () => {
                            this.switchPlans('personal');
                        },
                        checklistHeader: "",
                        checklistItems: []
                    },
                    {
                        bestValue: false,
                        name: "Enterprise",
                        image: "🏙️",
                        buttonLabel: "Contact Sales",
                        buttonCommand: () => {
                            this.followLink('https://www.tokenx.is/#home-contact');
                        },
                        checklistHeader: "Everything in Business and...",
                        checklistItems: [
                            "Organization-wide deployment",
                            "Advanced reporting and analytics tools",
                            "API access",
                            "Early access to new product features",
                            "Flexible payment options"
                        ]
                    }
                ]
            }
        };
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    goToCheckout(planId: string) {
        this.chargebee.openCheckout({
            hostedPage: () => {
                return firstValueFrom(this.cbService.createHostedPage(planId));
            },
            loaded: () => {
                // console.log("checkout opened");
            },
            error: () => {
                this.store.dispatch(EmitAlertAction({
                    message: "There was an error creating your subscription. Please try again later.",
                    alert_type: "error"
                }));
            },
            close: () => {
                // this.goToDashboard();
            },
            success: (hostedPageId: string) => {
                this.planType = "success";
            }
        });
    }

    switchPlans(type: string) {
        this.planType = type;
    }

    followLink(url: string) {
        if (url) {
            window.open(url, "_blank");
        }
    }

    goToDashboard() {
        this.router.navigateByUrl("dashboard");
    }
}