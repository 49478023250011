import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { RequestMethod, ResponseType } from "../utilities/constants";


/**
 * @class
 * @name BaseService
 * @description
 * Base service that all services should extend. Sets up the headers.
 */
export class BaseService {
    public static POST = RequestMethod.POST;
    public static GET = RequestMethod.GET;
    public static PUT = RequestMethod.PUT;
    public static PATCH = RequestMethod.PATCH;
    public static DELETE = RequestMethod.DELETE;
    public static API = window.location.hostname === "localhost"
        // ? "http://127.0.0.1:3000"
        ? "http://localhost:3000"
        : `${window.location.protocol}//${window.location.hostname}`;

    constructor(
        public http: HttpClient,
        public oauthService: OAuthService
    ) {}

    makeRequest(
        options: {
            method: RequestMethod;
            url: string;
            baseUrl?: string;
            responseType?: string;
            body?: {};
            headerOptions?: {};
            files?: any;
        }
    ): Observable<any> {
        let reqUrl = (options.baseUrl !== undefined
            ? options.baseUrl
            : (BaseService.API + "/")) + options.url;

        let headers: any = {};

        let token = this.oauthService.getAccessToken();
        if (token) {
            headers["Authorization"] = "Bearer " + token;
        }

        let httpOptions = {
            withCredentials: true,
            headers: headers
        };

        switch (options.responseType) {
            case ResponseType.BLOB: {
                httpOptions = Object.assign(
                    httpOptions,
                    options.headerOptions,
                    {
                        responseType: ResponseType.BLOB
                    }
                );
                break;
            }

            case ResponseType.TEXT: {
                httpOptions = Object.assign(
                    httpOptions,
                    options.headerOptions,
                    {
                        responseType: ResponseType.TEXT
                    }
                );
                break;
            }

            case ResponseType.ARRAY_BUFFER: {
                httpOptions = Object.assign(
                    httpOptions,
                    options.headerOptions,
                    {
                        responseType: ResponseType.ARRAY_BUFFER
                    }
                );
                break;
            }

            default: {
                httpOptions = Object.assign(
                    httpOptions,
                    options.headerOptions
                );
            }
        }

        switch (options.method) {
            case RequestMethod.GET: {
                const get$ = this.http.get(reqUrl, httpOptions).pipe(
                    map((res: any) => {
                        return res;
                    }),
                    shareReplay()
                );
                return get$;
            }
            case RequestMethod.POST: {
                let payload = new FormData();

                let body: any = options.body;

                for (const key of Object.getOwnPropertyNames(body)) {
                    const value: any = body[key];
                    payload.append(key, value)
                }

                if (options.files) {
                    payload.set('file', options.files)
                }

                return this.http
                    .post(
                        reqUrl,
                        payload,
                        httpOptions,
                    )
                    .pipe(
                        map((res: any) => {
                            return res;
                        })
                    );
            }
            case RequestMethod.PATCH: {
                return this.http
                    .patch(
                        reqUrl,
                        options.body,
                        httpOptions
                    )
                    .pipe(
                        map((res: any) => {
                            return res;
                        })
                    );
            }
            case RequestMethod.PUT: {
                return this.http
                    .put(
                        reqUrl,
                        options.body,
                        httpOptions
                    )
                    .pipe(
                        map((res: any) => {
                            return res;
                        })
                    );
            }
            case RequestMethod.DELETE: {
                const deleteOptions = Object.assign({}, httpOptions, {
                    body: options.body
                });
                return this.http.request("delete", reqUrl, deleteOptions).pipe(
                    map((res: any) => {
                        return res;
                    })
                );
            }
        }
    }
}
