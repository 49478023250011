import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { OAuthService } from "angular-oauth2-oidc";

import { AppStore } from "../store";
import { DecodeUserInfoAction } from "../user";


@Component({
    selector: "landing-page",
    templateUrl: "./landing.component.html",
    styleUrls: ["./landing.component.scss"]
})
export class LandingPageComponent implements OnInit {
    constructor(
        private store: Store<AppStore>,
        private oauthService: OAuthService
    ) {}

    ngOnInit() {}

    login() {
        this.oauthService
            .loadDiscoveryDocumentAndLogin()
            .then(response => {
                if (this.oauthService.hasValidIdToken()) {
                    this.store.dispatch(DecodeUserInfoAction(this.oauthService.getIdToken()));
                }

            }).catch(error => {
                console.log("Login error:", error);
                if (window.location.pathname.split("/").pop() !== "") {
                    // Redirect user to url of login page (hosted)
                    window.location.href = window.location.origin;
                }
            });
    }
}