<div class="wrapper">
    <div class="login-con">
        <div class="image-con">
            <img src="assets/images/tokenx-d.png">
        </div>

        <div class="actions-con">
            <p-button
                label="Log In"
                [style]="{ 'width': '100%' }"
                (onClick)="login()"
            ></p-button>

            <p>
                Don't have an account? <a href="#" (click)="login()">Sign up here</a>
            </p>
        </div>
    </div>

    <div class="img-con">
        <img src="assets/images/rubix.png">
    </div>
</div>
