import { Pipe, PipeTransform } from "@angular/core";


@Pipe({ name: "approxPercent" })
export class ApproximatePercentagePipe implements PipeTransform {
    constructor () {}

    transform(
        current: number,
        max: number,
        step: number = 10
    ): number {
        let percent = (current / max) * 100;
        return Math.floor(percent / step) * step;
    }
}