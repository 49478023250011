import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { AppStore } from "../../../store";
import { getErrorCode } from "../../../layout";


@Component({
    selector: "error-page",
    templateUrl: "./error-page.component.html",
    styleUrls: ["./error-page.component.scss"]
})
export class ErrorPageComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    public errorCode: number = 404;

    constructor(
        private store: Store<AppStore>
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(getErrorCode).subscribe(error => {
                this.errorCode = error;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}