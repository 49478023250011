import { Pipe, PipeTransform } from "@angular/core";


@Pipe({ name: "coinCost" })
export class CoinCostPipe implements PipeTransform {
    constructor () {}

    transform(cost: number): string {
        let converted = "";
        let s = cost.toString();

        if (cost <= 0) {
            return "0";

        } else if (cost >= 0.0001) {
            let i = s.indexOf(".");

            if (i < 0) {
                converted = s;

            } else {
                converted = s.substring(0, i + 5); // Leave 4 decimal places visible
                if (s.length > i + 5) { // Add ellipsis if truncated
                    converted += "...";
                }
            }

        } else {
            /* Not using subscript notation anymore
            converted = "0.0<sub>";
            let subCount = 0;

            if (s.indexOf("e") > -1) {
                // Convert from scientific notation first
                let n = s.indexOf("e-");
                let e = Number(s.substring(n + 2));     // Store power from string
                s = s.substring(0, n);                  // Remove power from string
                s = s.split(".").join("");              // Remove decimal point from base
                s = "0." + "".padEnd(e - 1, "0") + s;   // Prepend base with zeroes based on power
            }

            s = s.substring(2); // Start after decimal point
            while(s[0] === "0") {
                // Count leading zeroes
                subCount++;
                s = s.substring(1);
            }

            converted += (subCount + "</sub>");
            converted += s;
            */

           // This case always starts with at least 4 zeroes
           return "0.0000...";
        }

        return converted;
    }
}