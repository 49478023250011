import { Pipe, PipeTransform } from "@angular/core";


@Pipe({ name: "fullNumber" })
export class FullNumberPipe implements PipeTransform {
    constructor () {}

    transform(n: number): string {
        let s = n.toString();
        let e = s.indexOf("e");

        if (e < 0) {
            return s;

        } else {
            let t = Number(s.substring(e + 2));
            return n.toFixed(e + t - 2);
        }
    }
}